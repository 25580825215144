<template>
  <v-skeleton-loader
    type="image"
    class="custom-fields"
    v-if="!customFields"
  />
  <v-card
    class="custom-fields"
    v-else-if="customFields.length"
  >
    <v-card-title>{{$t('t.CustomFields')}}</v-card-title>
    <v-card-text>
      <custom-fields-columns
        :custom-fields.sync="internalCustomFields"
        :cols="4"
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
    CustomFieldsColumns: () => import('@/components/custom-fields-columns')
  },
  computed: {
    internalCustomFields: {
      get () {
        return this.customFields
      },
      set (customFields) {
        this.$emit('update:custom-fields', customFields)
      }
    }
  },
  props: {
    customFields: Array
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field
  margin-right 1em
</style>
